import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Subscription from '@/components/subscription/Subscription';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import numberFormatter from '@/formatters/ShortNumberConverter';
import { SubscriptionPlaylistDataFormatter } from '@/formatters/SubscriptionDataFormatter';
import useSubscription from '@/hooks/account/useSubscription';
import usePlaylistMetrics from '@/hooks/metrics/usePlaylistMetrics';
import { InsightsDataModel, MetricsPlaylistsModel } from '@/models/Metrics';

import Loading from '../../../utility/Loading';
import ViewAllModal from '../../ViewAllModal';

const AmazonInsights = () => {
  const { t } = useTranslation();

  const { isSubscribed } = useSubscription();
  const [chosenInsight, setChosenInsight] = useState<InsightsDataModel>();

  const [featuredPlaylists, setFeaturedPlaylists] = useState<MetricsPlaylistsModel[]>();
  const [playlistLoading, setPlaylistLoading] = useState<boolean>(true);

  const { playlistMetrics } = usePlaylistMetrics({ platform: 'amazon' });

  useEffect(() => {
    if (!playlistMetrics) return;

    if (isSubscribed) {
      setFeaturedPlaylists(playlistMetrics);
    } else {
      setFeaturedPlaylists(SubscriptionPlaylistDataFormatter(playlistMetrics));
    }

    setPlaylistLoading(false);
  }, [isSubscribed, playlistMetrics]);

  return (
    <>
      <ViewAllModal insights={chosenInsight} onClose={() => setChosenInsight(undefined)} />
      <div className="d-flex gap20">
        <div className="card flex-w50p w100p-lg-down">
          <div className="d-flex jc-space-between mb20">
            <h5>{t('INSIGHTS-PAGE.SPOTIFY.FEATURED-PLAYLISTS')}</h5>
            {isSubscribed ? (
              <ButtonComponent
                isCustom
                onClick={() => {
                  setChosenInsight({
                    title: 'INSIGHTS-PAGE.SPOTIFY.FEATURED-PLAYLISTS',
                    type: 'featured-playlists',
                    data: featuredPlaylists,
                  });
                }}
              >
                <p className="pt4 cursor-pointer">{t('COMMON.VIEW-ALL')}</p>
              </ButtonComponent>
            ) : (
              <Subscription
                type="element"
                element={<img className="ml-auto" src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
              />
            )}
          </div>
          {playlistLoading && <Loading size="small" />}
          {!playlistLoading && (!featuredPlaylists || featuredPlaylists.length === 0) && <p>No data</p>}
          {!playlistLoading &&
            featuredPlaylists?.slice(0, 5).map((item, index: number) => (
              <div
                key={index}
                className={`d-flex gap20 mt20 list-item campaign ${
                  isSubscribed || index === 0 ? '' : 'subscription-item-blur'
                }`}
                // onClick={() => {
                //   window.open(
                //     `https://open.spotify.com/playlist/${item.playlist.spotifyId}`
                //   );
                // }}
              >
                <img src={item?.playlist.coverImageUrl} alt="" />
                <div className="mt-auto mb-auto">
                  <p>{item?.playlist.name}</p>
                  <p className="text-faded">
                    {numberFormatter(item?.playlist.numberOfTracks)} {t('INSIGHTS-PAGE.COMMON.TRACKS-LOWER')}
                  </p>
                </div>
                <div className="ml-auto mt-auto mb-auto">{index + 1}</div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default AmazonInsights;
