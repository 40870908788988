import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { DateTimes } from '@/constants/DateTimes';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useLineChartData from '@/hooks/charts/useLineChartData';
import usePlatformArtistMetrics from '@/hooks/metrics/usePlatformArtistMetrics';
import { MetricsDataModel } from '@/models/Metrics';

import LineChartComponent from '../../../charts/LineChart';
import DateTimeSelector from '../../../utility/microcomponents/DateTimeSelector';

const SoundcloudInsights = () => {
  const { t } = useTranslation();

  const [selectedStartDate, setSelectedStartDate] = useState<string>();

  const { platformArtistMetrics } = usePlatformArtistMetrics({
    platform: 'soundcloud',
    fromDate: selectedStartDate,
    toDate: DateTimes.yesterdayDate,
  });

  const followerDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Followers',
        color: 'soundcloud',
        key: 'value',
        dataMap: new Map(
          platformArtistMetrics?.followers.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const followersChartData = useLineChartData<MetricsDataModel>({
    startDate: selectedStartDate,
    data: followerDataMap,
  });

  const handleDate = useCallback((date: string) => {
    setSelectedStartDate(date);
  }, []);

  return (
    <>
      <div className="d-flex gap20">
        <div className="card flex-w50p w100p-lg-down">
          <div className="d-flex jc-space-between mb8">
            <h3>{t('INSIGHTS-PAGE.COMMON.FOLLOWERS')}</h3>
            <DateTimeSelector dateOutput={handleDate} defaultRange="This year" />
          </div>
          {followersChartData && (
            <h5>
              {followersChartData
                ? numberFormatter(followersChartData.data[followersChartData.data?.length - 1]?.value || 0)
                : 0}
            </h5>
          )}
          <div>
            <LineChartComponent data={followersChartData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SoundcloudInsights;
