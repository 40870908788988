import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Modal } from '@mui/material';

import { CountryNameToCountryCodeConverter } from '@/formatters/CountryNameConverter';
import useSubscription from '@/hooks/account/useSubscription';
import {
  AudienceMetricsTypeGuardModel,
  FeaturedPlaylistsModel,
  InsightsDataModel,
  InstagramAudienceMetricsBrandModel,
  InstagramAudienceMetricsCitiesModel,
  InstagramAudienceMetricsCountriesModel,
  InstagramAudienceMetricsFollowersModel,
  InstagramAudienceMetricsInterestModel,
  SpotifyAudienceLocationsDataMetricsModel,
  YoutubeAudienceMetricsSubscribersModel,
  YoutubeMarketCoverageCitiesMetrics,
  YoutubeMarketCoverageCountriesMetrics,
} from '@/models/Metrics';
import { SpotifyArtistModel, SpotifySearchDataItemsModel } from '@/models/Spotify';

import { Images } from '../../constants/Images';
import numberFormatter from '../../formatters/ShortNumberConverter';
import ProgressBar from '../utility/ProgressBar';

const isBrandModel = (data: AudienceMetricsTypeGuardModel): data is InstagramAudienceMetricsBrandModel =>
  data && typeof data.name === 'string' && typeof data.weight === 'number' && typeof data.affinity === 'number';

const isInterestModel = (data: AudienceMetricsTypeGuardModel): data is InstagramAudienceMetricsInterestModel =>
  data && typeof data.name === 'string' && typeof data.weight === 'number' && typeof data.affinity === 'number';

const isFollowersModel = (data: AudienceMetricsTypeGuardModel): data is InstagramAudienceMetricsFollowersModel =>
  data && typeof data.fullName === 'string' && typeof data.picture === 'string' && typeof data.followers === 'number';

const isInstagramCitiesModel = (data: AudienceMetricsTypeGuardModel): data is InstagramAudienceMetricsCitiesModel =>
  data && typeof data.name === 'string' && typeof data.country === 'string' && typeof data.percent === 'number';

const isInstagramCountriesModel = (
  data: AudienceMetricsTypeGuardModel
): data is InstagramAudienceMetricsCountriesModel =>
  data && typeof data.name === 'string' && typeof data.code === 'string' && typeof data.percent === 'number';

const isSpotifyLocationsModel = (
  data: AudienceMetricsTypeGuardModel
): data is SpotifyAudienceLocationsDataMetricsModel =>
  data &&
  typeof data.name === 'string' &&
  typeof data.countryCode === 'string' &&
  typeof data.monthlyListeners === 'number';

const isYouTubeCitiesModel = (data: AudienceMetricsTypeGuardModel): data is YoutubeMarketCoverageCitiesMetrics =>
  data && typeof data.name === 'string' && typeof data.countryCode === 'string' && typeof data.views === 'number';

const isYouTubeCountriesModel = (data: AudienceMetricsTypeGuardModel): data is YoutubeMarketCoverageCountriesMetrics =>
  data &&
  typeof data.name === 'string' &&
  typeof data.percentageOfTotalViews === 'number' &&
  typeof data.views === 'number';

const isTopTracksModel = (data: AudienceMetricsTypeGuardModel): data is SpotifySearchDataItemsModel =>
  data && typeof data.name === 'string' && typeof data.popularity === 'number';

const isSimilarArtistsModel = (data: AudienceMetricsTypeGuardModel): data is SpotifyArtistModel =>
  data && typeof data.name === 'string' && typeof data.popularity === 'number';

const isFeaturedPlaylistModel = (data: AudienceMetricsTypeGuardModel): data is FeaturedPlaylistsModel =>
  data && typeof data.playlist === 'object';

const isYoutubeSubscribersModel = (
  data: AudienceMetricsTypeGuardModel
): data is YoutubeAudienceMetricsSubscribersModel =>
  data && typeof data.fullName === 'string' && typeof data.subscribers === 'number';

const ViewAllModal = ({ insights, onClose }: { insights?: InsightsDataModel; onClose: () => void }) => {
  const { t } = useTranslation();

  const { isSubscribed } = useSubscription();

  if (!insights) return <></>;

  return (
    <>
      <Modal open={!!insights} onClose={onClose}>
        <div className="to-do-modal">
          <div className="d-flex jc-space-between mb16">
            <div className=" mt-auto mb-auto">
              <div>
                <h5>{t(insights?.title)}</h5>
                <p className="text-faded small">{insights?.subtitle}</p>
              </div>
            </div>
            <Button className="m0 btn-black-2" onClick={onClose}>
              <Icon>close</Icon>
            </Button>
          </div>
          <div className="max-h400 overflow-scroll pr10">
            {insights?.data?.map((item, index: number) => {
              if (insights?.type === 'progress-affinity' && (isBrandModel(item) || isInterestModel(item))) {
                return (
                  <div key={index} className={`mb20 ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}>
                    <div className="d-flex jc-space-between">
                      <p className="small">{item.name}</p>
                      <p className="small text-faded">{item.affinity.toFixed(2)}x</p>
                    </div>
                    <ProgressBar progress={item.affinity * 10} tooltip={item.affinity.toFixed(2)} title="" label={''} />
                  </div>
                );
              } else if (insights?.type === 'progress-location') {
                let transformedData: { name: string; countryCode: string; count: number; progress: number } = {
                  name: '',
                  countryCode: '',
                  count: 0,
                  progress: 0.0,
                };

                if (isInstagramCitiesModel(item)) {
                  transformedData = {
                    name: item.name,
                    countryCode: CountryNameToCountryCodeConverter(item.country),
                    count: item.followers,
                    progress: item.percent,
                  };
                } else if (isInstagramCountriesModel(item)) {
                  transformedData = {
                    name: item.name,
                    countryCode: item.code,
                    count: item.followers,
                    progress: item.percent,
                  };
                } else if (isSpotifyLocationsModel(item)) {
                  transformedData = {
                    name: item.name,
                    countryCode: item.countryCode,
                    count: item.monthlyListeners,
                    progress: item.percentageOfTotalMonthlyListeners,
                  };
                } else if (isYouTubeCitiesModel(item)) {
                  transformedData = {
                    name: item.name,
                    countryCode: item.countryCode,
                    count: item.views,
                    progress: item.percentageOfTotalViews,
                  };
                } else if (isYouTubeCountriesModel(item)) {
                  transformedData = {
                    name: item.name,
                    countryCode: item.name,
                    count: item.views,
                    progress: item.percentageOfTotalViews,
                  };
                }

                return (
                  <div key={index} className={`mb20 ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}>
                    <div className="d-flex jc-space-between">
                      <p className="small">
                        <ReactCountryFlag
                          countryCode={transformedData.countryCode}
                          style={{
                            fontSize: '2em',
                          }}
                        />
                        <span className="pl8">{transformedData.name}</span>
                      </p>
                      <p className="small text-faded mt-auto mb-auto">{numberFormatter(transformedData.count)}</p>
                    </div>
                    <ProgressBar
                      progress={transformedData.progress}
                      tooltip={transformedData.progress.toFixed(2)}
                      title=""
                      label={''}
                    />
                  </div>
                );
              } else if (insights?.type === 'top-songs' && isTopTracksModel(item)) {
                return (
                  <div
                    key={index}
                    className={`d-flex gap20 mb20 list-item campaign ${
                      !isSubscribed && index > 0 ? 'subscription-item-blur' : ''
                    }`}
                    onClick={() => {
                      window.open(item.external_urls.spotify);
                    }}
                  >
                    <img src={item.album.images[2].url} alt="" />
                    <div className="mt-auto mb-auto">
                      <p>{item.name}</p>
                      <p className="text-faded">
                        {item.popularity} {t('INSIGHTS-PAGE.SPOTIFY.POPULARITY-LOWER')}
                      </p>
                    </div>
                    <div className="ml-auto mt-auto mb-auto">{index + 1}</div>
                  </div>
                );
              } else if (insights?.type === 'similar-artists' && isSimilarArtistsModel(item)) {
                return (
                  <div
                    key={index}
                    className={`d-flex gap20 mb20 list-item campaign ${
                      !isSubscribed && index > 0 ? 'subscription-item-blur' : ''
                    }`}
                    onClick={() => {
                      window.open(`https://open.spotify.com/artist/${item.id}`);
                    }}
                  >
                    <img src={item.images[0].url} alt="" />
                    <div className="mt-auto mb-auto">
                      <p>{item.name}</p>
                    </div>
                    <div className="ml-auto mt-auto mb-auto">{index + 1}</div>
                  </div>
                );
              } else if (insights.type === 'list-item') {
                let transformedData: { name: string; picture: string; count: number } = {
                  name: '',
                  picture: '',
                  count: 0,
                };

                if (isFollowersModel(item)) {
                  transformedData = {
                    name: item.fullName,
                    count: item.followers,
                    picture: item.picture,
                  };
                } else if (isYoutubeSubscribersModel(item)) {
                  transformedData = {
                    name: item.fullName,
                    count: item.subscribers,
                    picture: item.picture,
                  };
                }

                return (
                  <div
                    key={index}
                    className={`d-flex mb20 list-item ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}
                  >
                    <div>
                      <object className="playlist-image" data={transformedData.picture} type="image/png">
                        <img className="playlist-image" src={Images.profilePlaceholder} alt="Fallback playlist img" />
                      </object>
                    </div>
                    <div className="mt-auto mb-auto">
                      <p>{transformedData.name}</p>
                      <p className="text-faded">{numberFormatter(transformedData.count)}</p>
                    </div>
                    <div className="ml-auto mt-auto mb-auto">{index + 1}</div>
                  </div>
                );
              } else if (insights.type === 'featured-playlists') {
                let transformedData: { name: string; picture: string; count: number; type?: string } = {
                  name: '',
                  picture: '',
                  count: 0,
                  type: '',
                };

                if (isFeaturedPlaylistModel(item)) {
                  transformedData = {
                    name: item.playlist.name,
                    count: item.playlist.followers || item.playlist.numberOfTracks,
                    picture: item.playlist.coverImageUrl,
                    type: item.playlist.followers ? 'followers' : 'tracks',
                  };
                }
                return (
                  <div
                    key={index}
                    className={`d-flex mb20 list-item ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}
                  >
                    <div>
                      <object className="playlist-image" data={transformedData.picture} type="image/png">
                        <img className="playlist-image" src={Images.profilePlaceholder} alt="Fallback playlist img" />
                      </object>
                    </div>
                    <div className="mt-auto mb-auto">
                      <p>{transformedData.name}</p>
                      <p className="text-faded">
                        {numberFormatter(transformedData.count) + ' ' + transformedData.type}
                      </p>
                    </div>
                    <div className="ml-auto mt-auto mb-auto">{index + 1}</div>
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewAllModal;
