import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Legend } from 'recharts';

import PieChartComponent from '@/components/charts/PieChart';
import { chartColors } from '@/constants/BrandColors';
import { platformNameConverter } from '@/formatters/PlatformNameConverter';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useSubscription from '@/hooks/account/useSubscription';
import usePlatformOverviewMetrics from '@/hooks/metrics/usePlatformOverviewMetrics';
import { ChartDataPieModel } from '@/models/Metrics';

import Subscription from '../../../subscription/Subscription';
import BenchmarkInsightsCTACards from '../../BenchmarkInsights/BenchmarckInsightsCTACard';

const MusicOverviewInsights = () => {
  const { isSubscribed } = useSubscription();
  const { t } = useTranslation();
  const { platformOverviewMetrics } = usePlatformOverviewMetrics({ type: 'music' });

  const [chartData, setChartData] = useState<ChartDataPieModel[]>();

  useEffect(() => {
    if (platformOverviewMetrics) {
      const data = platformOverviewMetrics.metrics
        .filter((item) => item.percentage !== 0)
        .map((item) => ({
          name: item.platform,
          value: item.percentage,
          fill: chartColors.pieChart.platforms[item.platform as keyof typeof chartColors.pieChart.platforms],
          metric: item.metric,
          actualValue: item.value,
        }));
      setChartData(data);
    }
  }, [platformOverviewMetrics]);

  return (
    <>
      <Subscription type="banner-small" />
      <div className={`d-flex flex-wrap gap20 ${isSubscribed ? '' : 'mt20'}`}>
        <div className={`card flex-w50p w100p-lg-down order-2-lg-down`}>
          <h3>{t('INSIGHTS-PAGE.COMMON.MUSIC-PLATFORM-PERFORMANCE')}</h3>
          <PieChartComponent data={chartData} suffix="%" isLegend={false} isOverview={true} />
          {platformOverviewMetrics && (
            <p className="text-faded mt16 text-center">
              {t('INSIGHTS-PAGE.COMMON.YOU-HAVE-A-TOTAL-OF')}{' '}
              <span className="text-white bold">{numberFormatter(platformOverviewMetrics?.totalValue)}</span>{' '}
              {t('INSIGHTS-PAGE.COMMON.FOLLOWERS-LOWER')}{' '}
              {t('INSIGHTS-PAGE.COMMON.ACROSS-YOUR-PLATFORMS').replace('{{TYPE}}', t('COMMON.MUSIC-LOWER'))}
            </p>
          )}
          {chartData && (
            <Legend
              wrapperStyle={{ position: 'relative', marginTop: '20px' }}
              iconType="square"
              payload={chartData.map((item) => {
                return {
                  id: item.name,
                  type: 'square',
                  value: <span className="text-white">{platformNameConverter(item.name)}</span>,
                  color: item.fill,
                };
              })}
            />
          )}
        </div>
        <BenchmarkInsightsCTACards />
      </div>
    </>
  );
};

export default MusicOverviewInsights;
