import React from 'react';

import Subscription from '../../../../subscription/Subscription';
import FollowersChart from './FollowersChart';

const TwitterInsights = () => {
  return (
    <>
      <div className="d-flex gap20 flex-item-wrap mb20">
        <FollowersChart />
      </div>
      <Subscription type="banner-small" />
    </>
  );
};

export default TwitterInsights;
