import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button, MenuItem, Select } from '@mui/material';

import useSubscription from '@/hooks/account/useSubscription';
import useBenchmarkInsights from '@/hooks/insights/useBenchmarkInsights';
import useUserTracking from '@/hooks/useUserTracking';
import { PromoteFlowQueryParams } from '@/models/Enums';

import Subscription from '../../subscription/Subscription';
import Loading from '../../utility/Loading';
import DialogModal from '../../utility/modals/DialogModal';

const BenchmarkInsightGauge = ({ platform }: { platform: string }) => {
  const [params, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const userTracking = useUserTracking();

  const { isSubscribed } = useSubscription();

  const [breakPointHit, setBreakPointHit] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedInfo, setSelectedInfo] = useState<{ title: string; content: string }>();

  const [noData, setNoData] = useState<boolean>(false);

  const [type, setType] = useState<string>(platform === 'YouTube' ? 'subscribers' : 'followers');

  const spotifyTypes = [
    { name: 'Follower Insight', value: 'followers' },
    { name: 'Listener Insight', value: 'listeners' },
  ];

  const {
    genre,
    absoluteValue,
    percentage,
    lowValue,
    lowValueString,
    highValue,
    highValueString,
    benchmarkInsightsIsLoading,
    benchmarkInsightsError,
  } = useBenchmarkInsights({ platform: platform.toLowerCase(), type });

  useEffect(() => {
    if (benchmarkInsightsError || !isSubscribed) {
      setNoData(true);
    }
  }, [benchmarkInsightsError, isSubscribed]);

  const style = { '--distance': percentage } as React.CSSProperties;

  const openInfo = (info?: { title: string; content: string }) => {
    if (!info) return;
    setSelectedInfo(info);
    setDialogOpen(true);
  };

  const goToPromotion = () => {
    switch (platform) {
      case 'YouTube':
        params.set(PromoteFlowQueryParams.META_ADS, 'true');
        params.set(PromoteFlowQueryParams.TODO_GOAL, 'youtube-views');
        setSearchParams(params);
        break;
      case 'Spotify':
        switch (type) {
          case 'followers':
            params.set(PromoteFlowQueryParams.META_ADS, 'true');
            params.set(PromoteFlowQueryParams.TODO_GOAL, 'spotify-followers');
            setSearchParams(params);
            break;
          case 'listeners':
            params.set(PromoteFlowQueryParams.PLAYLISTING, 'true');
            setSearchParams(params);
            break;
        }
        break;
      case 'TikTok':
        params.set(PromoteFlowQueryParams.TIKTOK_ADS, 'true');
        params.set(PromoteFlowQueryParams.TODO_GOAL, 'tiktok-followers');
        setSearchParams(params);
        break;
      case 'Instagram':
        params.set(PromoteFlowQueryParams.META_ADS, 'true');
        params.set(PromoteFlowQueryParams.TODO_GOAL, 'instagram-followers');
        setSearchParams(params);
        break;
      default:
        params.set(PromoteFlowQueryParams.PLAYLISTING, 'true');
        setSearchParams(params);
    }
  };

  useEffect(() => {
    if (platform && type && percentage) {
      userTracking?.benchmarkInsightsViewed(platform, type, percentage);
    }
  }, [percentage, platform, type, userTracking]);

  const similarArtistsInfo = useCallback(
    (extended?: boolean) => {
      if (!genre || !lowValueString || !highValueString) return;

      return {
        title: t('BENCHMARK-INSIGHTS.INFO.TITLES.SIMILAR-ARTISTS')
          .replace('{{platform}}', platform)
          .replace('{{genre}}', genre),
        content: `${
          t('BENCHMARK-INSIGHTS.INFO.DESCRIPTIONS.SIMILAR-ARTISTS') +
          (extended ? t('BENCHMARK-INSIGHTS.INFO.DESCRIPTIONS.EXTENDED-SIMILAR-ARTISTS') : '')
        }`
          .replace('{{platform}}', platform)
          .replace('{{genre}}', genre)
          .replace('{{lowNumber}}', lowValueString)
          .replace('{{highNumber}}', highValueString)
          .replace('{{type}}', type),
      };
    },
    [genre, highValueString, lowValueString, platform, t, type]
  );

  const minimumListenersInfo = useCallback(() => {
    if (!genre || !lowValueString) return;

    return {
      title: t('BENCHMARK-INSIGHTS.INFO.TITLES.MINIMUM-LISTENER-GROWTH')
        .replace('{{platform}}', platform)
        .replace('{{genre}}', genre)
        .replace('{{type}}', type),
      content: (lowValue < 0
        ? t('BENCHMARK-INSIGHTS.INFO.DESCRIPTIONS.MINIMUM-LISTENER-GROWTH-MINUS')
        : t('BENCHMARK-INSIGHTS.INFO.DESCRIPTIONS.MINIMUM-LISTENER-GROWTH')
      )
        .replace('{{platform}}', platform)
        .replace('{{genre}}', genre)
        .replaceAll('{{type}}', type)
        .replace('{{lowNumber}}', lowValueString),
    };
  }, [genre, lowValue, lowValueString, platform, t, type]);

  const maximumListenersInfo = useCallback(() => {
    if (!genre || !highValueString) return;
    return {
      title: t('BENCHMARK-INSIGHTS.INFO.TITLES.MAXIMUM-LISTENER-GROWTH')
        .replace('{{platform}}', platform)
        .replace('{{genre}}', genre)
        .replace('{{type}}', type),
      content: (highValue < 0
        ? t('BENCHMARK-INSIGHTS.INFO.DESCRIPTIONS.MAXIMUM-LISTENER-GROWTH-MINUS')
        : t('BENCHMARK-INSIGHTS.INFO.DESCRIPTIONS.MAXIMUM-LISTENER-GROWTH')
      )
        .replace('{{platform}}', platform)
        .replace('{{genre}}', genre)
        .replaceAll('{{type}}', type)
        .replace('{{highNumber}}', highValueString),
    };
  }, [genre, highValue, highValueString, platform, t, type]);

  const newBenchmarkString = useCallback(() => {
    const original = t('BENCHMARK-INSIGHTS.GAINED-AND-BEAT');
    const gained = original.replace(
      '{{gained}}',
      absoluteValue > 0 ? t('BENCHMARK-INSIGHTS.GAINED') : t('BENCHMARK-INSIGHTS.LOST')
    );
    const finalString = gained
      .replaceAll('{{type}}', absoluteValue === 1 ? type.substring(0, type.length - 1) : type)
      .replace('{{percentage}}', percentage.toString())
      .replace('{{number}}', Math.abs(absoluteValue).toLocaleString());

    return finalString;
  }, [absoluteValue, percentage, t, type]);

  const handleResize = () => {
    window.visualViewport && window.visualViewport.width <= 945 ? setBreakPointHit(true) : setBreakPointHit(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    newBenchmarkString();
  }, [newBenchmarkString]);

  return (
    <>
      <DialogModal
        open={dialogOpen}
        output={() => {
          setDialogOpen(false);
          setSelectedInfo(undefined);
        }}
        title={selectedInfo?.title ?? ''}
        content={selectedInfo?.content.toLocaleString()}
        isInfoDialog={true}
      />

      {breakPointHit && (
        <div className="d-flex gap20">
          <div className="w100p">
            <div className="card gradient-card">
              <div className="d-flex jc-space-between mb20 flex-wrap">
                {platform !== 'Spotify' && <h3 className="">{t('BENCHMARK-INSIGHTS.YOUR-INSIGHT')}</h3>}
                {platform === 'Spotify' && (
                  <Select
                    className="date-time-selector min-w200"
                    value={type}
                    defaultValue=""
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    {spotifyTypes.map((item, index: number) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <div className="card-inner pt8 pb8 pl16 pr16 br20 d-flex gap8">
                  <img src={`/images/logos/${platform.toLowerCase()}-logo.svg`} alt="" />
                  <p className="mt-auto mb-auto text-faded">{platform}</p>
                </div>
              </div>
              {benchmarkInsightsIsLoading && <Loading size="small" />}
              {!benchmarkInsightsIsLoading && noData && !isSubscribed && (
                <Subscription
                  type="element"
                  element={<p className="overlay-text cursor-pointer">{t('BENCHMARK-INSIGHTS.NOT-SUBSCRIBED')}</p>}
                />
              )}
              {!benchmarkInsightsIsLoading && noData && isSubscribed && (
                <p className="overlay-text">{t('BENCHMARK-INSIGHTS.NO-DATA-YET')}</p>
              )}

              {!benchmarkInsightsIsLoading && (
                <div className={`${noData ? 'subscription-item-blur' : ''}`}>
                  <p className="text-reduced-opacity mb20">
                    {t('BENCHMARK-INSIGHTS.IN-THE-LAST-14-DAYS')}
                    <span className="text-white">{newBenchmarkString()}</span>
                    <span
                      className="text-white underline"
                      onClick={() => {
                        openInfo(similarArtistsInfo(true));
                      }}
                    >
                      {t('BENCHMARK-INSIGHTS.SIMILAR-ARTISTS')}
                    </span>
                    .
                  </p>
                  <div className="pos-rel max-w267 m-auto">
                    <img className="pl20 pr20 pt0" src="/images/insight-gauge.svg" alt="" />
                    <img style={style} className="gauge-arrow" src="/images/arrow.svg" alt="" />
                    <div className="gauge-centered-stats text-center">
                      <p className="text-faded">{t('BENCHMARK-INSIGHTS.YOU-BEAT')}</p>
                      <h2 className="mt-4 mb0">{percentage}%</h2>
                      <p className="mt-4 text-faded">
                        {t('BENCHMARK-INSIGHTS.OF')}{' '}
                        <span
                          className="text-white underline"
                          onClick={() => {
                            openInfo(similarArtistsInfo());
                          }}
                        >
                          {t('BENCHMARK-INSIGHTS.SIMILAR-ARTISTS')}
                        </span>
                      </p>
                    </div>
                    <div className="d-flex jc-space-between pl58 pr58 mt-10">
                      <p
                        className="underline"
                        onClick={() => {
                          openInfo(minimumListenersInfo());
                        }}
                      >
                        min
                      </p>
                      <p
                        className="underline"
                        onClick={() => {
                          openInfo(maximumListenersInfo());
                        }}
                      >
                        max
                      </p>
                    </div>
                  </div>
                  <p className="mt20 text-reduced-opacity">
                    {absoluteValue <= 0
                      ? t('BENCHMARK-INSIGHTS.TURN-UP-THE-HEAT')
                      : t('BENCHMARK-INSIGHTS.YOU-ARE-DOING-GREAT')}{' '}
                    <span>{t('BENCHMARK-INSIGHTS.TAKE-ACTION')}</span>
                  </p>
                  <Button
                    className="btn-white mt20 w100p ml0"
                    onClick={() => {
                      goToPromotion();
                    }}
                  >
                    {t('BENCHMARK-INSIGHTS.LAUNCH-GROWTH-CAMPAIGN')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!breakPointHit && (
        <div className="card">
          <div className="d-flex jc-space-between mb20">
            {platform !== 'Spotify' && <h3 className="">{t('BENCHMARK-INSIGHTS.YOUR-INSIGHT')}</h3>}
            {platform === 'Spotify' && (
              <Select
                className="date-time-selector min-w200"
                value={type}
                defaultValue=""
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                {spotifyTypes.map((item, index: number) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            <div className="card-inner pt8 pb8 pl16 pr16 br20 d-flex gap8">
              <img src={`/images/logos/${platform.toLowerCase()}-logo.svg`} alt="" />
              <p className="mt-auto mb-auto text-faded">{platform}</p>
            </div>
          </div>
          {benchmarkInsightsIsLoading && <Loading size="small" />}
          {!benchmarkInsightsIsLoading && noData && !isSubscribed && (
            <Subscription
              type="element"
              element={<p className="overlay-text cursor-pointer">{t('BENCHMARK-INSIGHTS.NOT-SUBSCRIBED')}</p>}
            />
          )}
          {!benchmarkInsightsIsLoading && noData && isSubscribed && (
            <p className="overlay-text">{t('BENCHMARK-INSIGHTS.NO-DATA-YET')}</p>
          )}
          {!benchmarkInsightsIsLoading && (
            <div className={`${noData ? 'subscription-item-blur' : ''}`}>
              <div className="d-flex gap20">
                <div className="card gradient-card flex-grow">
                  <div className="gradient-card-overlay">
                    <h3 className="text-reduced-opacity">
                      {t('BENCHMARK-INSIGHTS.IN-THE-LAST-14-DAYS')}
                      <span className="text-white">{newBenchmarkString()}</span>
                      <span
                        className="text-white underline"
                        onClick={() => {
                          openInfo(similarArtistsInfo(true));
                        }}
                      >
                        {t('BENCHMARK-INSIGHTS.SIMILAR-ARTISTS')}
                      </span>
                      .{' '}
                      {absoluteValue <= 0
                        ? t('BENCHMARK-INSIGHTS.TURN-UP-THE-HEAT')
                        : t('BENCHMARK-INSIGHTS.YOU-ARE-DOING-GREAT')}{' '}
                      {t('BENCHMARK-INSIGHTS.TAKE-ACTION')}
                    </h3>

                    <Button
                      className="btn-white m0 b16 pos-abs"
                      onClick={() => {
                        goToPromotion();
                      }}
                    >
                      {t('BENCHMARK-INSIGHTS.LAUNCH-GROWTH-CAMPAIGN')}
                    </Button>
                  </div>
                </div>
                <div className="card gradient-card gauge-card pos-rel">
                  <h5 className="mb20">{t('BENCHMARK-INSIGHTS.HOW-YOU-MEASURE-UP')}</h5>
                  <img className="pl20 pr20 pt0" src="/images/insight-gauge.svg" alt="" />
                  <img style={style} className="gauge-arrow" src="/images/arrow.svg" alt="" />
                  <div className="gauge-centered-stats text-center">
                    <p className="text-faded">{t('BENCHMARK-INSIGHTS.YOU-BEAT')}</p>
                    <h2 className="mt-4 mb0">{percentage}%</h2>
                    <p className="mt-4 text-faded">
                      {t('BENCHMARK-INSIGHTS.OF')}{' '}
                      <span
                        className="text-white underline"
                        onClick={() => {
                          openInfo(similarArtistsInfo());
                        }}
                      >
                        {t('BENCHMARK-INSIGHTS.SIMILAR-ARTISTS')}
                      </span>
                    </p>
                  </div>
                  <div className="d-flex jc-space-between pl58 pr58 mt-10">
                    <p
                      className="underline"
                      onClick={() => {
                        openInfo(minimumListenersInfo());
                      }}
                    >
                      min
                    </p>
                    <p
                      className="underline"
                      onClick={() => {
                        openInfo(maximumListenersInfo());
                      }}
                    >
                      max
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BenchmarkInsightGauge;
