import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import LineChartComponent from '@/components/charts/LineChart';
import DateTimeSelector from '@/components/utility/microcomponents/DateTimeSelector';
import useLineChartData from '@/hooks/charts/useLineChartData';
import useInstagramAudienceHistoricMetrics from '@/hooks/metrics/useInstagramAudienceHistoricMetrics';
import { MetricsDataModel } from '@/models/Metrics';

const LikesChart = () => {
  const { t } = useTranslation();

  const [selectedStartDate, setSelectedStartDate] = useState<string>();

  const { instagramAudienceHistoricMetrics } = useInstagramAudienceHistoricMetrics({ fromDate: selectedStartDate });

  const followersDataMap = useMemo(() => {
    if (!instagramAudienceHistoricMetrics) return;
    return [
      {
        name: 'Likes',
        color: 'instagram',
        key: 'value',
        dataMap: new Map(
          instagramAudienceHistoricMetrics?.metrics.map((item) => [
            format(new Date(item.date), 'yyyy-MM-dd'),
            item.averageLikesPerPost,
          ])
        ),
      },
    ];
  }, [instagramAudienceHistoricMetrics]);

  const likesChartData = useLineChartData<MetricsDataModel>({
    startDate: selectedStartDate,
    data: followersDataMap,
  });

  const handleDate = useCallback((date: string) => {
    setSelectedStartDate(date);
  }, []);

  return (
    <div className="card-inner flex-grow flex-w30p min-w300">
      <h5 className="mb20">{t('INSIGHTS-PAGE.INSTAGRAM.LIKES-PER-POST')}</h5>
      <div className="text-center">
        <DateTimeSelector toggleButtons dateOutput={handleDate} defaultRange="This year" />
      </div>
      <LineChartComponent data={likesChartData} />
    </div>
  );
};

export default LikesChart;
